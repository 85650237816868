import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import DTable from "../Table";

import {
  Spinner,
  Table,
  Pagination,
  Col,
  Row,
  Card,
  Badge,
  Dropdown,
  Tab,
  Nav,
  Button,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import { getProfile } from "../../../store/actions/AuthActions";
import { exportToExcel } from "react-json-to-excel";

import Select from "react-select";
import stateData from "../../../utils/states.json";
// import Ext from "../../layouts/Ext";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import GoogleMap from "google-maps-react-markers";
import Marker from "./marker";
import swal from "sweetalert";

import moment from "moment";
import "moment-timezone";

const Home = ({ history }) => {
  const dispatch = useDispatch();

  const [statistics, setStatistics] = useState(null);
  const profile = useSelector((state) => state.auth.profile);

  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [data, setData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const [options, setOptions] = useState({});

  const [activeToggle, setActiveToggle] = useState(0);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState(false);
  const [startDate, setStartDate] = useState(dateToEpoch2(new Date()));
  const [endDate, setEndDate] = useState(dateToEpoch2(new Date()));

  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  function dateToEpoch2(thedate) {
    //const date = thedate.setHours(0,0,0,0);
    //return moment(date).toDate();
    return thedate.toJSON().split("T")[0];
  }

  const onDateFilter = async () => {
    setFilter(true);
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const onMarkerClick = (id) => {
    console.log("This is ->", id);
  };

  useEffect(() => {
    dispatch(getProfile());
    loadStatistics();
    loadDevices();
  }, [dispatch]);

  useEffect(() => {
    if(device){
      loadDeviceData(device);
    }
  }, [device]);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    if(device){
      loadDeviceData(device);
    }
  }, [page, pageSize]);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const onChangeDevice = (k) => {
    //setLoading(true)
    setActiveToggle(k);
    setDevice(devices[Number(k)]);

    // if(mapRef){
    //   mapRef.current = null
    // }

    //   setTimeout(() => {
    //   setLoading(false);
    // }, 1000)
  };

  const loadDevices = async () => {
    const { data } = await axiosInstance.post("user/devices", {
      PAGE: 0,
      LIMIT: 9999999,
      SEARCH: "",
    });

    if (data.status) {
      setDevices(data.devices);
      setActiveToggle("0");

      if (data.devices.length > 0) {
        setDevice(data.devices[0]);
      }
    } else {
      setDevices([]);
    }
  };


  useEffect(() => {
    if(device){
      loadDeviceData(device);
    }
    
    
  }, [filter]);

  useEffect(() => {
    if (filter) {
      if(device){
        loadDeviceData(device);
      }
    }
  }, [startDate, endDate]);

  //Devices
  const loadDeviceData = async (d) => {
    setLoading(true);
    const { data } = await axiosInstance.post("user/device-data", {
      page: page,
      limit: pageSize,
      search: "",
      id: d.id,
      filter: filter,
      start_date: startDate,
      end_date: endDate,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setData(data.data);
    } else {
      setMaxPage(1);
      setData([]);
    }
  };

  async function loadStatistics() {
    try {
      const { data } = await axiosInstance.get("admin/statistics");

      console.log("statistics data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        setStatistics(data);
      }
    } catch (error) {}
  }

  const columns = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "TIMESTAMP",
      accessor: "TIMESTAMP",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.TIMESTAMP
          ? moment(new Date(d.TIMESTAMP)).utc().format("DD-MM-YYYY hh:mm A") //format(new Date(d.TIMESTAMP), "dd/MM/yyyy H:mma",{ timeZone: 'Asia/Calcutta' })
          : "No";
      },
    },
    {
      Header: "LOAD",
      accessor: "LOAD",
      sort: false,
    },
    {
      Header: "STATUS",
      accessor: "STATUS",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return status(d);
      },
    },
    {
      Header: "FREQUENCY",
      accessor: "POPFREQ1",
      sort: false,
    },
    {
      Header: "RMS Voltage (V)",
      accessor: "POPV1",
      sort: false,
    },

    {
      Header: "RMS Current (Amp)",
      accessor: "POPI1",
      sort: false,
    },
    {
      Header: "Array Power (W)",
      accessor: "MPPT",
      sort: false,
    },
    {
      Header: "Array Voltage (V)",
      accessor: "PDC1V1",
      sort: false,
    },
    {
      Header: "Array Current (Amp)",
      accessor: "PDC1I1",
      sort: false,
    },
    {
      Header: "TEMPRATURE",
      accessor: "TEMP",
      sort: false,
    },
    {
      Header: "VDC",
      accessor: "V_DC",
      sort: false,
    },
    {
      Header: "DUTY",
      accessor: "DUTY",
      sort: false,
    },
    {
      Header: "Flow Rate (LPM)",
      accessor: "POPFLW1",
      sort: false,
    },
    {
      Header: "DISCHARGE (In Ltr)",
      accessor: "POPDWD1",
      sort: false,
    },
    {
      Header: "CUMULATIVE DISCHARGE (In Ltr)",
      accessor: "POPTOTWD1",
      sort: false,
    },
    {
      Header: "RUNNIG Hrs",
      accessor: "PDHR1",
      sort: false,
    },
    {
      Header: "CUMULATIVE RUNNIG Hrs",
      accessor: "PTOTHR1",
      sort: false,
    },
    {
      Header: "ENERGY GENERATED (kWh)",
      accessor: "PDKWH1",
      sort: false,
    },
    {
      Header: "CUMULATIVE ENERGY GENERATED (kWh)",
      accessor: "PTOTKWH1",
      sort: false,
    },
  ];

  const status = (data) => {
    if (data.COND == 300 && data.PRUNST1 == 1) {
      return "RUN";
    } else if (data.COND == 300 && data.PRUNST1 == 0) {
      return "STOP";
    } else if (data.COND == 306) {
      return "LOW POWER";
    } else if (data.COND == 302) {
      return "FAULT-SHORT CIRCUIT";
    } else if (data.COND == 307) {
      return "FAULT-DRY RUN";
    } else if (data.COND == 301) {
      return "FAULT-OVER CURRENT";
    } else if (data.COND == 303) {
      return "FAULT-OVER VOLTAGE";
    } else if (data.COND == 308) {
      return "FAULT-OPEN CIRCUIT";
    } else if (data.COND == 309) {
      return "FAULT-OVER TEMPRATURE";
    } else if (data.COND == 310) {
      return "FAULT-LOW WATER LEVEL";
    } else if (data.COND == 318) {
      return "FAULT-BOOST SATURATION";
    } else {
      return "UNKNOWN - " + data.COND;
    }
  };

  const onExportReport = async () => {
    const { data } = await axiosInstance.post("user/device-data", {
      page: 0,
      limit: 9999999999,
      search: "",
      id: device.id,
      filter: filter,
      start_date: startDate,
      end_date: endDate,
    });

    if (data.status) {
      const report = data.data.map((x, i) => {
        return {
          No: i + 1,
          //"TIMESTAMP":x.TIMESTAMP,
          TIMESTAMP: moment(new Date(x.TIMESTAMP))
            .utc()
            .format("DD-MM-YYYY hh:mm A"),
          LOAD: x.LOAD,
          STATUS: status(x),
          FREQUENCY: x.POPFREQ1,
          "RMS Voltage (V)": x.POPV1,
          "RMS Current (Amp)": x.POPI1,
          "Array Power (W)": x.MPPT,
          "Array Voltage (V)": x.PDC1V1,
          "Array Current (Amp)": x.PDC1I1,
          TEMPRATURE: x.TEMP,
          VDC: x.V_DC,
          DUTY: x.DUTY,
          "Flow Rate (LPM)": x.POPFLW1,
          "DISCHARGE (In Ltr)": x.POPDWD1,
          "CUMULATIVE DISCHARGE (In Ltr)": x.POPTOTWD1,
          "RUNNIG Hrs": x.PDHR1,
          "CUMULATIVE RUNNIG Hrs": x.PTOTHR1,
          "ENERGY GENERATED (kWh)": x.PDKWH1,
          "CUMULATIVE ENERGY GENERATED (kWh)": x.PTOTKWH1,
        };
      });

      exportToExcel(
        report,
        `${device.S_NO}-REPORT-${moment().format("DD-MM-YYYY h:mm")}.csv`
      );
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          {devices.length == 0 ? (
            <h5> No Device Found!</h5>
          ) : (
            <Tab.Container defaultActiveKey="0">
              <Nav
                as="ul"
                className="nav-pills light"
                role="tablist"
                defaultActiveKey={0}
                activeKey={activeToggle}
                onSelect={(k) => onChangeDevice(k)}
              >
                {devices.map((d, i) => {
                  return (
                    <Nav.Item key={i}>
                      <Nav.Link eventKey={i}>{d.S_NO}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Tab.Container>
          )}
        </Card.Header>
      </Card>

      {device ? (
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">ASN_11</p>
                        <h4 className="mb-0">{device?.ASN_11}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">IMEI</p>
                        <h4 className="mb-0">{device?.IMEI}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">SIMCARD_NO</p>
                        <h4 className="mb-0">{device?.SIMCARD_NO ?? "No"}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">STATUS</p>
                        <h4 className="mb-0">{statistics?.status}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">ARRAY POWER</p>
                        <h4 className="mb-0">
                          {statistics?.last_data?.MPPT} W
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">FREQUENCY</p>
                        <h4 className="mb-0">
                          {statistics?.last_data?.POPFREQ1}Hz
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">MQTT STATUS</p>
                        <h4 className="mb-0">
                          {statistics?.last_data?.V_1 == 1
                            ? "CONNECTED"
                            : "DISCONNECTED"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">SIGNAL STENGHT</p>
                        <h4 className="mb-0">{statistics?.last_data?.RSSI}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">LAST UPDATE</p>
                        <h4 className="mb-0">
                          {statistics?.last_data != null
                            ? moment(new Date(statistics?.last_data?.TIMESTAMP))
                                .utc()
                                .format("DD-MM-YYYY hh:mm A")
                            : "Not Available"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">VILLAGE</p>
                        <h4 className="mb-0">{device?.VILLAGE}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">USER</p>
                        <h4 className="mb-0">{device?.CUSTOMER_NAME}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">MOBILE NUMBER</p>
                        <h4 className="mb-0">{device?.CUSTOMER_PHONE}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">COMPANY</p>
                        <h4 className="mb-0">{device?.company_name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">STATE</p>
                        <h4 className="mb-0">{device?.STATE}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
                <div className="widget-stat card">
                  <div className="card-body p-2">
                    <div className="media ai-icon">
                      <div className="media-body">
                        <p className="mb-1">DISTRICT</p>
                        <h4 className="mb-0">{device?.DISTRICT}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-12">
              <div className="widget-stat card">
                <div className="card-body p-0">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <p className="mb-1 p-2">MAP</p>

                      <>
                        <GoogleMap
                          apiKey="AIzaSyCgaUL7e5aiZv_wxwG2mgUIvv1QkQbtPGM"
                          defaultCenter={{
                            lat: 28.6448,
                            lng: 77.216721,
                          }}
                          defaultZoom={5}
                          options={{
                            mapTypeControl: false,
                            fullscreenControl: false,
                            maxZoom: 20,
                            minZoom: 3,
                            rotateControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            mapTypeId: "roadmap",
                            zoomControl: true,
                          }}
                          mapMinHeight="220px"
                          onGoogleApiLoaded={onGoogleApiLoaded}
                          onChange={(map) => console.log("Map moved", map)}
                        >
                          <Marker
                            lat={device ? device.LAT : 0}
                            lng={device ? device.LONG : 0}
                            markerId={device ? device.S_NO : ""}
                            onClick={onMarkerClick}
                          />
                        </GoogleMap>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Row className="mb-4">
        <Col md="12">
          <h5 className="h5 my-4">Report</h5>
        </Col>

        <Col md="4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              inputVariant="outlined"
              label="Start Date"
              value={startDate}
              format="dd/MM/yyyy"
              placeholder="dd/MM/yyyy"
              onChange={(date) => {
                setStartDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md="4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              inputVariant="outlined"
              label="End Date"
              value={endDate}
              format="dd/MM/yyyy"
              placeholder="dd/MM/yyyy"
              onChange={(date) => {
                setEndDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>

        <Col md="4">
          {filter ? (
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => {
                setFilter(false);
                setStartDate(dateToEpoch2(new Date()));
                setEndDate(dateToEpoch2(new Date()));
              }}
            >
              Clear Filter
            </Button>
          ) : (
            <Button
              className="btn btn-sm btn-primary"
              onClick={() => onDateFilter()}
            >
              Apply Filter
            </Button>
          )}

          <Button className="btn btn-sm btn-primary ml-4" onClick={() => onExportReport()}>
            Export
          </Button>
        </Col>

        <Col md="12">

        {data.length == 0 ? (
                <p className="my-4">No Device Report Found</p>
              ) : loading ? (
                <div className="text-center py-4"><Spinner animation="border" variant="primary" /></div>
              ) : (
                <DTable
                  columns={columns}
                  data={data}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}

        </Col>
      </Row>
    </>
  );
};

export default Home;
