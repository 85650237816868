import React,{ useState,useEffect} from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from './Logout';
/// Image
import profileImg from "../../../images/profile/17.jpg";
//import logo from "../../../images/logo";
import { Dropdown,Button } from "react-bootstrap";

import axiosInstance from '../../../services/AxiosInstance';
import { useDispatch,useSelector } from "react-redux";

import {
	getProfile,
	profileAction
 } from '../../../store/actions/AuthActions';

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {

  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const profile = useSelector((state) => state.auth.profile);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;


	useEffect(() => {
		loadProfile();
		//dispatch(getProfile());
	  }, [dispatch]);


	async function loadProfile(){
		
		try {
			
			const { data } = await axiosInstance.get(
        		"admin/profile"
      		);


	  console.log("profile data - "+JSON.stringify(data));

		if(data.status === false){
			
		}else{
			//setUser(data.user);
			dispatch(profileAction(data.profile));
			console.log("profile data - "+JSON.stringify(data));
		}
		
		}catch (error) {
			

		}
	
	  }


  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">
					<div className="header-left">
						<div
							className="dashboard_bar"
							id="nav_title"
							style={{ textTransform: "capitalize" }}
						  >
							{finalName.join(" ").length === 0
							  ? "Dashboard"
							  : finalName.join(" ")}
						</div>
					</div> 	
					<ul className="navbar-nav header-right">
						


						<div className="nav-item">
						
						</div>
						
					



						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle as="a" to="#" variant="" className="nav-link i-false c-pointer">								
								<img src="https://img.icons8.com/color/90/null/user.png" width="20" alt=""/>
									
                                
							</Dropdown.Toggle>
							<Dropdown.Menu align="right" className="mt-2">
							  <Link to="/my-profile" className="dropdown-item ai-icon">
								<svg
								  id="icon-user1"
								  xmlns="http://www.w3.org/2000/svg"
								  className="text-primary"
								  width={18}
								  height={18}
								  viewBox="0 0 24 24"
								  fill="none"
								  stroke="currentColor"
								  strokeWidth={2}
								  strokeLinecap="round"
he 								>
								  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
								  <circle cx={12} cy={7} r={4} />
								</svg>
								<span className="ml-2">Profile </span>
							  </Link>
							  
                               <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

export default Header;
